import './TextArea.scss';

import React from 'react';

import { classNames } from '../../utils';

function TextArea({className, ...rest}) {
  return (
    <textarea className={classNames('textarea', className)} {...rest}></textarea>
  );
}

export default TextArea;
