import './RadioGroup.scss';

import React from 'react';

import { classNames } from '../../utils';

function RadioGroup({options, label, name, className, isRequired, ...rest}) {
  return (
    <div className={classNames('radio-group', className)}>
      <p className="radio-group__label">{label}</p>
      <div className="radio-group__options">
        {options.map((option, i) => (
          <div key={option.value} className="radio-group__option">
            <input id={`${name}-${i}`} type="radio" name={name} value={option.value} required={isRequired} />
            <label htmlFor={`${name}-${i}`}>{option.value}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RadioGroup;
