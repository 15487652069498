import './PageBody.scss';

import React from 'react';

import { classNames } from '../../utils';

function PageBody({children, className}) {
  return (
    <div className={classNames('page-body', className)}>
      {children}
    </div>
  )
}

export default PageBody;
