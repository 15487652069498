import './Contact.scss';

import React from 'react';

import Button from '../../components/Button';
import ContactInfo from '../../components/ContactInfo';
import Layout from '../../components/Layout';
import PageBody from '../../components/PageBody';
import PageHeader from '../../components/PageHeader';
import RadioGroup from '../../components/RadioGroup';
import SEO from '../../components/SEO';
import TextArea from '../../components/TextArea';
import TextField from '../../components/TextField';
import SubHeader from '../../components/SubHeader';

const inquiryTypes = [
  { value: 'Participant' },
  { value: 'Broker' },
  { value: 'Employer' },
  { value: 'Other' }
];

class ContactPage extends React.PureComponent {
  state = {
    status: ''
  }

  submitForm = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }

  render() {
    const { status } = this.state;
    return (
      <Layout>
        <SEO title="Contact Us" />
            <SubHeader title="Contact Us" />
        <PageHeader className="contact-page__header" title="" message="Get in Touch with Tri-Star" />
        <PageBody className="contact-page">
          <form
            className="contact-form"
            onSubmit={this.submitForm}
            action="https://formspree.io/xqwobegx"
            method="POST"
          >
            <input type="text" name="_gotcha" style={{display: "none"}} />
            <TextField name="name" placeholder="Name*" required />
            <TextField name="company" placeholder="Company" />
            <TextField name="address" placeholder="Address" />
            <TextField name="address2" placeholder="Address (cont.)" />
            <TextField name="city" placeholder="City" />
            <TextField name="state" placeholder="State" />
            <TextField type="number" name="zip" placeholder="Zip Code" />
            <TextField type="email" name="email" placeholder="Email*" required />
            <TextField type="tel" name="telephone" placeholder="Phone*" required />

            <RadioGroup label="Type of Inquiry*" name="inquiryType" options={inquiryTypes} isRequired />

            <TextArea name="comments" placeholder="Comments*" required />

            <div className="u-align-right">
              <Button className="contact-form__submit" type="submit" appearance="primary">Submit</Button>
            </div>
            {status === "SUCCESS" && <p className="contact-message contact-message--success">Your message was received. Thank You!</p>}
            {status === "ERROR" && <p className="contact-message contact-message--error">There was an error.</p>}
          </form>

          <ContactInfo />
        </PageBody>
      </Layout>
    )
  }
}

export default ContactPage
